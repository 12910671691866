<!--  -->
<template>
  <div class="approval-detail">
    <div class="base-info card">
      <div class="row1">
        <div class="title">
          <p class="r"></p>
          <p class="name">{{ info.osName }}</p>
        </div>
      </div>
      <div class="row2">
        <span class="label">数量：</span>
        <span class="value">{{ info.numCount }}</span>
      </div>
      <div class="row2">
        <span class="label">型号：</span>
        <span class="value">{{ info.modelType }}</span>
      </div>
      <div class="row2">
        <span class="label">采购日期：</span>
        <span class="value">{{ info.purchaseDate || "--" }}</span>
      </div>
      <div class="row2">
        <span class="label">描述：</span>
        <span class="value">{{ info.remarkDesc || "--" }}</span>
      </div>
      <div class="row3">
        <span class="label">图片：</span>
        <div class="value">
          <template v-if="info.picList">
            <img
              v-for="(file, i) in info.picList"
              :key="i"
              :src="file.url"
              alt=""
              class="img"
            />
          </template>
          <span v-else class="no-img">无</span>
        </div>
      </div>
    </div>

    <!-- 操作按钮 -->
    <!-- 1-正常 2-故障 3-报废 -->
    <div class="footer-buttons">
      <div class="icon-text" @click="handleEdit">
        <img :src="edit" alt="" class="img" />
        <span class="text">编辑</span>
      </div>
    </div>
  </div>
</template>

<script>
import { queryOfficeSuppliesInfo } from "@/api/officeSupplies/index.js";
import edit from "@/assets/images/fixedAssets/edit.png";
import { Toast } from "vant";
export default {
  data() {
    return {
      processConfigInfo: {},
      info: {},
      nodesList: [],
      edit,
    };
  },
  name: "approvalDetail",
  components: {},
  computed: {
    type() {
      return this.$route.query.type;
    },
  },
  filters: {},
  created() {},
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      queryOfficeSuppliesInfo({
        osCode: this.$route.query.osCode,
      }).then((res) => {
        this.info = res.data;
      });
    },

    // 编辑-带code
    handleEdit() {
      this.$router.push({
        path: "/addSupplies",
        query: {
          osCode: this.$route.query.osCode,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.approval-detail {
  min-height: 100%;
  background: #fafafa;
  width: 7.5rem;
  position: relative;
  padding: 1px;
  .card {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    background: #fff;
    margin-bottom: 0.3rem;
    border: 1px solid transparent;
  }
  .base-info {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    .row1 {
      margin-bottom: 0.16rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        line-height: 0.48rem;
        display: flex;
        align-items: center;
        .r {
          width: 0.06rem;
          height: 0.28rem;
          background: #0094ee;
          border-radius: 0.03rem;
          margin-right: 0.1rem;
        }
        .name {
          font-size: 0.34rem;
          font-weight: bold;
          color: #333333;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .row2 {
      // height: 0.37rem;
      font-size: 0.26rem;
      color: #999999;
      line-height: 0.37rem;
      display: flex;
      // justify-content: space-between;
      margin-bottom: 0.3rem;
      .label {
        font-size: 0.26rem;
        font-weight: 400;
        color: #999;
        line-height: 0.37rem;
      }
      .value {
        width: 5.3rem;
        font-size: 0.26rem;
        font-weight: 400;
        color: #333333;
        line-height: 0.37rem;
      }
    }
    .row3 {
      display: flex;
      flex-direction: column;
      .label {
        font-size: 0.26rem;
        font-weight: 400;
        color: #999;
        line-height: 0.37rem;
      }
      .value {
        display: flex;
        justify-content: space-between;
        .img {
          margin-top: 0.2rem;
          width: 2.2rem;
          height: 1.7rem;
          border-radius: 0.16rem;
          border: 1px solid #f0f0f0;
        }
        .no-img {
          color: #666;
        }
      }
    }
  }

  .footer-buttons {
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    padding-right: 0.3rem;
    width: 7.5rem;
    height: 1.04rem;
    background: #ffffff;
    box-shadow: 0 0.06rem 0.3rem 0 rgba(0, 0, 0, 0.1);
    align-items: center;
    .icon-text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 2.5rem;
      .img {
        width: 0.4rem;
        height: 0.4rem;
      }
      .text {
        width: 0.52rem;
        height: 0.37rem;
        font-size: 0.26rem;
        color: #333333;
        line-height: 0.37rem;
        white-space: nowrap;
      }
    }
  }
}
</style>
